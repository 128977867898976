import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { DarkModeSwitch, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, ForwardedDiv } from '@components';
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useOnClickOutside } from '@hooks';
import { CustomRangesModel, DatePickerProps, DatePickerRange, Themes, WeekdaysModel } from '@interfaces';
import { getCustomRanges, startOfDay } from '@static';
import { getDateRanges } from '@utils';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import moment, { Moment } from 'moment';
import { v4 } from 'uuid';

type ElementRef = HTMLDivElement | null;

export const DatePicker = ({ dateRange, applyRange, theme }: DatePickerProps): ReactElement => {
  const [range, setRange] = useState<DatePickerRange>(getDateRanges(dateRange));
  const [pickerTheme, setPickerTheme] = useState<Themes>(theme || (localStorage.getItem('datePickerTheme') as Themes) || 'light');
  const [utcMoment] = useState(moment().utc(true));
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [customRangeLabel, setCustomRangeLabel] = useState<string>('');
  const [customRanges, setCustomRanges] = useState<CustomRangesModel[]>(getCustomRanges() ?? []);
  const [addCustomRange, setAddCustomRange] = useState<boolean>(false);
  const [showFromMonthDropdown, setShowFromMonthDropdown] = useState<boolean>(false);
  const [showToMonthDropdown, setShowToMonthDropdown] = useState<boolean>(false);
  const [showCurrentYearDropdown, setShowCurrentYearDropdown] = useState<boolean>(false);
  const [showCurrentToYearDropdown, setShowCurrentToYearDropdown] = useState<boolean>(false);
  const [showCurrentFromHourDropdown, setShowCurrentFromHourDropdown] = useState<boolean>(false);
  const [showCurrentFromMinuteDropdown, setShowCurrentFromMinuteDropdown] = useState<boolean>(false);
  const [showCurrentToHourDropdown, setShowCurrenTotHourDropdown] = useState<boolean>(false);
  const [showCurrentToMinuteDropdown, setShowCurrentToMinuteDropdown] = useState<boolean>(false);
  const [fromDateMonth, setFromDateMonth] = useState<number>(moment().utc(true).month());
  const [toDateMonth, setToDateMonth] = useState<number>(moment().utc(true).month() + 1);
  const [currentFromYear, setCurrentFromYear] = useState<number>(utcMoment.year());
  const [currentFromHour, setCurrentFromHour] = useState<number>(utcMoment.endOf('day').hour() + 1);
  const [currentFromMinutes, setCurrentFromMinutes] = useState<number>(utcMoment.endOf('hour').minutes());
  const [currentToYear, setCurrentToYear] = useState<number>(utcMoment.year());
  const [currentToHour, setCurrentToHour] = useState<number>(utcMoment.endOf('day').hour() + 1);
  const [currentToMinutes, setCurrentToMinutes] = useState<number>(utcMoment.endOf('hour').minutes());
  const [customRangeError, setCustomRangeError] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const datePickerRef = useRef<ElementRef>(null);
  const fromDateMonthRef = useRef<ElementRef>(null);
  const fromDateYearRef = useRef<ElementRef>(null);
  const fromDateHourRef = useRef<ElementRef>(null);
  const fromDateMinuteRef = useRef<ElementRef>(null);
  const toDateMonthRef = useRef<ElementRef>(null);
  const toDateYearRef = useRef<ElementRef>(null);
  const toDateHourRef = useRef<ElementRef>(null);
  const toDateMinuteRef = useRef<ElementRef>(null);

  const allMonths = useMemo(() => Array.apply(0, Array(12)).map((_, i) => utcMoment.utc(true).month(i).format('MMMM')), [utcMoment]);
  const years = useMemo(
    () => Array.apply(0, Array(32)).map((_, i) => utcMoment.year(new Date().getFullYear()).subtract(i, 'year').format('YYYY')),
    [utcMoment]
  );
  const hours = useMemo(() => Array.apply(0, Array(24)).map((_, i) => utcMoment.endOf('day').hour() + 1 - i), [utcMoment]);
  const minutes = useMemo(() => Array.apply(0, Array(60)).map((_, i) => utcMoment.endOf('hour').minutes(i).format('m')), [utcMoment]);

  const [weekDays] = useState<WeekdaysModel[]>([
    { label: 'Su', value: 0 },
    { label: 'Mo', value: 1 },
    { label: 'Tu', value: 2 },
    { label: 'We', value: 3 },
    { label: 'Th', value: 4 },
    { label: 'Fr', value: 5 },
    { label: 'Sa', value: 6 },
  ]);

  /** @description Closes date picker when clicked outside of bounds and resets some values */
  const handleOutsideClick = () => {
    setShowPicker(false);
    setAddCustomRange(false);
    setCustomRangeLabel('');
    setCustomRangeError('');
  };

  /**
   * @description Set range from a pre-defined value (custom range)
   */
  const handleSetCustomRanges = (value: { start: string; end: string }) => {
    const fromMonth = moment(value.start).utc(true).month();
    const utcStart = moment.utc(value.start);
    const utcEnd = moment.utc(value.end);
    setFromDateMonth(fromMonth);
    setToDateMonth(fromMonth === 11 ? 0 : toDateMonth);
    setCurrentFromYear(utcStart.year());
    setCurrentToYear(utcEnd.year());
    setCurrentFromHour(utcStart.hours());
    setCurrentToHour(utcEnd.hours());
    setCurrentFromMinutes(utcStart.minutes());
    setCurrentToMinutes(utcEnd.minutes());
    setRange({ start: utcStart, end: utcEnd });
    applyRange({ start: utcStart, end: utcEnd });
  };

  /**
   * @description Adds a custom range and saves it to local storage
   */
  const handleAddCustomRange = (label: string, value: { start: string; end: string }) => {
    const allRanges = localStorage.getItem('ranges');
    const parsedRanges = JSON.parse(allRanges || '');
    if (!label) {
      setCustomRangeError('Label cannot be empty.');
      return;
    }
    const data = {
      id: v4(),
      label,
      value,
    };
    localStorage.setItem('ranges', JSON.stringify([...(parsedRanges ?? []), data]));
    setCustomRanges([...customRanges, data]);
    setAddCustomRange(false);
    setCustomRangeLabel('');
  };

  /**
   * @description Deletes a custom range from local storage
   */
  const handleDeleteCustomRange = (id: string) => {
    const allRanges = localStorage.getItem('ranges');
    const parsedRanges: CustomRangesModel[] = allRanges ? JSON.parse(allRanges) : [];
    const rangeToDelete: CustomRangesModel | undefined = parsedRanges.find(r => r.id === id);
    const newRanges = parsedRanges.filter(r => r.id !== rangeToDelete?.id);
    localStorage.setItem('ranges', JSON.stringify(newRanges));
    const newCustomRanges = customRanges.filter(r => r.id !== rangeToDelete?.id);
    setCustomRanges(newCustomRanges);
  };

  /**
   * @description Set date range based on start and end
   */
  const handleSetRange = async (value: Moment) => {
    const newRange = {
      start: moment.utc(value.clone()).startOf('day'),
      end: moment.utc(value.clone()).endOf('day'),
    };

    if (currentFromYear < currentToYear) {
      newRange.start.set('year', currentFromYear);
      if (value.year() !== currentFromYear) {
        newRange.start.set('year', currentToYear);
      }
      if (
        (value.isSame(range.start) || value.isBefore(range.start) || range.end.diff(range.start, 'days') >= 1) &&
        (value.year() === currentFromYear || value.year() === currentToYear)
      ) {
        setRange(newRange);
        applyRange(newRange);
      } else {
        setRange({
          start: range.start.utc(true).startOf('day'),
          end: newRange.end,
        });
        applyRange({
          start: range.start.utc(true).startOf('day'),
          end: newRange.end,
        });
      }
    }
    // if the value that is given is the same as start of before
    // set both start and end to that value (reset the range)
    if (value.isSame(range.start) || value.isBefore(range.start) || range.end.diff(range.start, 'days') >= 1) {
      setRange(newRange);
      applyRange(newRange);
    } else {
      setRange({
        start: range.start.utc(true).startOf('day'),
        end: newRange.end,
      });
      applyRange({
        start: range.start.utc(true).startOf('day'),
        end: newRange.end,
      });
    }
  };

  const setPreviousMonth = () => {
    const months = moment.months().length;

    if (fromDateMonth === 0) {
      setFromDateMonth(months - 1);
      setToDateMonth(toDateMonth - 1);
      setCurrentFromYear(currentFromYear - 1);
      return;
    }
    if (toDateMonth === 0) {
      setFromDateMonth(fromDateMonth - 1);
      setToDateMonth(months - 1);
      setCurrentToYear(currentToYear - 1);
      return;
    }
    setFromDateMonth(fromDateMonth - 1);
    setToDateMonth(toDateMonth - 1);
  };

  const setNextMonth = () => {
    if (toDateMonth === 11) {
      setCurrentToYear(currentToYear + 1);
      setFromDateMonth(fromDateMonth + 1);
      setToDateMonth(0);
      return;
    }

    if (fromDateMonth === 11) {
      setCurrentFromYear(currentFromYear + 1);
      setFromDateMonth(0);
      setToDateMonth(toDateMonth + 1);
      return;
    }

    setFromDateMonth(fromDateMonth + 1);
    setToDateMonth(toDateMonth + 1);
  };

  /** @description Highlight the cells that are in the selected range */
  const getHighlightedDays = (value: Moment) => {
    if (value.isBefore(range.end) && value.isAfter(range.start)) {
      return true;
    }
    return false;
  };

  /** @description Get the values at the end of the row to round the edges */
  const getEndOfRowSelectedValue = (index: number) => (index + 1) % 7 === 0;

  /** @description Get the values at the start of the row to round the edges */
  const getStartOfRowSelectedValue = (index: number) => index % 7 === 0;

  /**
   *  @description Get all cells to render into the UI
   *  @returns An array of JSX Elements containing all the dates as Moment type
   * */
  const getDateCells = (isFrom = true) => {
    // maximum rows of days in view
    const rowLength = 7;
    // maximum amount of days in array
    const maximumArrayLength = 42;
    const totalDaysInCalendar = [];
    const fromOrToMonth = isFrom ? fromDateMonth : toDateMonth;
    const firstWeekDayOfCurrentMonth = moment.utc().month(fromOrToMonth).startOf('month').weekday();

    // fill the gap with the previous weekdays left in the calendar
    for (let i = 1; i <= firstWeekDayOfCurrentMonth; i += 1) {
      const firstDayOfTheMonth = moment.utc().month(fromOrToMonth).startOf('month').subtract(i, 'day');
      if (isFrom) {
        firstDayOfTheMonth.set('year', currentFromYear);
      } else {
        firstDayOfTheMonth.set('year', currentToYear);
      }
      if (range.start.isBefore(range.end, 'y')) {
        firstDayOfTheMonth.set('year', currentFromYear);
      }
      totalDaysInCalendar.unshift(firstDayOfTheMonth);
    }

    // fill the rest of the array with all the days of the current month
    for (let i = 0; i < moment.utc().month(fromOrToMonth).daysInMonth(); i += 1) {
      const currentDay = moment.utc().month(fromOrToMonth).startOf('month');
      if (isFrom) {
        currentDay.set('year', currentFromYear);
      } else {
        currentDay.set('year', currentToYear);
      }
      totalDaysInCalendar.push(currentDay);
      currentDay.add(i, 'days');
    }

    const lastRowValues = totalDaysInCalendar.slice(35);
    // based on the last rows length, which depends on the days of the month
    // fill the last remaining row(s) with the remaining weekdays of the next month
    let daysToAdd = 0;
    for (
      let i = lastRowValues.length === 0 ? totalDaysInCalendar.length - 1 : 0;
      i < (lastRowValues.length === 0 ? maximumArrayLength - 1 : rowLength - lastRowValues.length);
      i += 1
    ) {
      const dayOfNextMonth = moment.utc().month(fromOrToMonth).add(1, 'months').startOf('month');

      totalDaysInCalendar.push(dayOfNextMonth);
      if (isFrom) {
        dayOfNextMonth.set('year', currentFromYear);
      } else {
        dayOfNextMonth.set('year', currentToYear);
      }
      if (range.start.isBefore(range.end, 'y')) {
        dayOfNextMonth.set('year', currentFromYear + 1);
      }
      dayOfNextMonth.add(daysToAdd, 'day');
      daysToAdd += 1;
    }

    return totalDaysInCalendar;
  };

  /** @description Hide the date picker when clicking outside of boundaries */
  useOnClickOutside(datePickerRef, handleOutsideClick);

  /** @description If any custom ranges are saved in local storage, set custom ranges state to render in the UI */
  useEffect(() => {
    const savedRanges = localStorage.getItem('ranges') || '';
    const parsedRanges = savedRanges ? JSON.parse(savedRanges) : [];
    if (parsedRanges) {
      localStorage.setItem('ranges', JSON.stringify(parsedRanges));
    }

    const mappedRanges = parsedRanges.map((r: { id: string; label: string; value: { start: string; end: string } }) => ({
      id: r.id,
      label: r.label,
      ...(r.value && {
        value: {
          start: r.value.start,
          end: r.value.end,
        },
      }),
    }));
    if (savedRanges) {
      setCustomRanges([...customRanges, ...mappedRanges]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // change the theme whenever the state changes to not depend only on local storage
    document.documentElement.setAttribute('data-theme', pickerTheme);
    localStorage.setItem('datePickerTheme', pickerTheme);
  }, [pickerTheme]);

  useEffect(() => {
    // if a theme is set in local storage, set the state based on that
    if (theme) {
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('datePickerTheme', theme);
    }
  }, [theme]);

  return (
    <AnimatePresence>
      <ForwardedDiv className='main-container' ref={datePickerRef}>
        <ForwardedDiv key='picker'>
          <ForwardedDiv
            ref={datePickerRef}
            onClick={() => {
              setShowPicker(!showPicker);
            }}
            className='date-picker-label-container'>
            <CalendarDaysIcon className='calendar-icon' />
            <motion.input
              id='label'
              ref={inputRef}
              className='date-picker-label'
              type='text'
              value={`${range.start.format()}  /  ${range.end.format()}`}
              readOnly
            />
          </ForwardedDiv>
        </ForwardedDiv>
        <AnimatePresence>
          {showPicker && (
            <ForwardedDiv key='picker' className='date-picker' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ForwardedDiv className='ranges'>
                <ForwardedDiv className='dates-container'>
                  <ForwardedDiv className='from-date-container'>
                    <ForwardedDiv className='from-date-text'>
                      <b>From date: </b>
                      {range.start.format()}
                    </ForwardedDiv>
                    <ForwardedDiv className='from-date-month-year-picker'>
                      <motion.div
                        id='left-icon'
                        whileTap={{
                          scale: 0.95,
                          backgroundColor: '#e9e9e9',
                        }}
                        onClick={setPreviousMonth}
                        className='from-date-month-year-picker-icons-container ml-2'>
                        <ChevronLeftIcon className='from-date-month-year-picker-icons' />
                      </motion.div>

                      <motion.div key={`${fromDateMonth}-${currentFromYear}`} className='from-date-month-year-picker-label space-x-10'>
                        <Dropdown className='ml-3' ref={fromDateMonthRef} onClose={() => setShowFromMonthDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowFromMonthDropdown(!showFromMonthDropdown)}>
                            {utcMoment.month(fromDateMonth).format('MMMM')}
                          </DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showFromMonthDropdown}>
                            {allMonths.map(month => (
                              <DropdownItem
                                key={month}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setFromDateMonth(moment().utc(true).month(month).month());
                                  setToDateMonth(moment().utc(true).month(month).month() + 1);
                                  setRange({
                                    ...range,
                                    start: range.start.set('month', fromDateMonth),
                                  });
                                  setShowFromMonthDropdown(false);
                                }}>
                                {month}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown ref={fromDateYearRef} onClose={() => setShowCurrentYearDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrentYearDropdown(!showCurrentYearDropdown)}>
                            {utcMoment.year(currentFromYear).format('YYYY')}
                          </DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentYearDropdown}>
                            {years.map(year => (
                              <DropdownItem
                                key={year}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setCurrentFromYear(+year);
                                  setRange({
                                    ...range,
                                    start: range.start.set('year', +year),
                                  });
                                  setShowCurrentYearDropdown(false);
                                }}>
                                {year}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown ref={fromDateHourRef} onClose={() => setShowCurrentFromHourDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrentFromHourDropdown(!showCurrentFromHourDropdown)}>{currentFromHour}</DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentFromHourDropdown}>
                            {hours.map(hour => (
                              <DropdownItem
                                key={hour}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setCurrentFromHour(+hour);
                                  setRange({
                                    ...range,
                                    start: range.start.set('hour', +hour),
                                  });
                                  setShowCurrentFromHourDropdown(false);
                                }}>
                                {hour}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown ref={fromDateMinuteRef} onClose={() => setShowCurrentFromMinuteDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrentFromMinuteDropdown(!showCurrentFromMinuteDropdown)}>
                            {currentFromMinutes}
                          </DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentFromMinuteDropdown}>
                            {minutes.map(minute => (
                              <DropdownItem
                                key={minute}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setCurrentFromMinutes(+minute);
                                  setRange({
                                    ...range,
                                    start: range.start.set('minute', +minute),
                                  });
                                  setShowCurrentFromMinuteDropdown(false);
                                }}>
                                {minute}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </motion.div>
                    </ForwardedDiv>
                    <ForwardedDiv className='weekdays'>
                      {weekDays.map(({ label, value }) => (
                        <motion.p key={value}>{label}</motion.p>
                      ))}
                    </ForwardedDiv>
                    <ForwardedDiv className='from-date'>
                      {getDateCells().map((cell, index) => (
                        <ForwardedDiv
                          key={`${cell.month()}-${cell.date()}`}
                          id={`from-date-${cell.date()}-${cell.month()}`}
                          className={classNames(
                            'from-date-cell',
                            getHighlightedDays(cell) ? 'from-date-cell-selected' : '',
                            cell.isSame(range.start) ? 'from-date-cell-selected-start' : '',
                            cell.isSame(range.start) && range.end.diff(range.start, 'days') >= 1 ? 'from-date-cell-selected-start-range' : '',
                            cell.month() === range.end.month() && cell.date() === range.end.date() ? 'from-date-cell-selected-end' : '',
                            cell.month() === range.end.month() && cell.date() === range.end.date() && range.end.diff(range.start, 'days') >= 1
                              ? 'from-date-cell-selected-end-range'
                              : '',
                            getEndOfRowSelectedValue(index) ? 'from-date-cell-selected-end-of-row from-date-cell-selected-end-of-row-hover' : '',
                            getStartOfRowSelectedValue(index) ? 'from-date-cell-selected-start-of-row from-date-cell-selected-start-of-row-hover' : '',
                            cell.isSame(moment(startOfDay).utc(true).startOf('day')) ? 'from-date-cell-today' : '',
                            cell.month() !== fromDateMonth ? 'from-date-cell-opacity' : ''
                          )}
                          onClick={() => {
                            handleSetRange(cell);
                            if (cell.month() !== fromDateMonth && cell.isBefore(range.end)) {
                              setFromDateMonth(cell.month());
                              setToDateMonth(cell.month() + 1);
                            }
                          }}>
                          {(cell.isSame(range.start) || (cell.month() === range.end.month() && cell.date() === range.end.date())) && <div>{cell.date()}</div>}
                          {cell.date()}
                        </ForwardedDiv>
                      ))}
                    </ForwardedDiv>
                  </ForwardedDiv>
                  {/** TO DATES */}
                  <ForwardedDiv className='to-date-container'>
                    <ForwardedDiv className='to-date-text'>
                      <b>To date: </b>
                      {range.end.format()}
                    </ForwardedDiv>
                    <ForwardedDiv className='from-date-month-year-picker'>
                      <motion.div
                        key={`${toDateMonth}-${currentToYear}`}
                        transition={{
                          type: 'spring',
                          stiffness: 260,
                          damping: 20,
                        }}
                        className='from-date-month-year-picker-label space-x-10'>
                        <Dropdown className='ml-3' ref={toDateMonthRef} onClose={() => setShowToMonthDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowToMonthDropdown(!showToMonthDropdown)}>
                            {utcMoment.month(toDateMonth).format('MMMM')}
                          </DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showToMonthDropdown}>
                            {allMonths.map(month => (
                              <DropdownItem
                                key={month}
                                disabled={moment().month(month).month() <= fromDateMonth}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setToDateMonth(moment().month(month).month());
                                  setRange({
                                    ...range,
                                    end: range.end.utc(true).set('month', toDateMonth),
                                  });
                                  setShowToMonthDropdown(false);
                                }}>
                                {month}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>

                        <Dropdown ref={toDateYearRef} onClose={() => setShowCurrentToYearDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrentToYearDropdown(!showCurrentToYearDropdown)}>{currentToYear}</DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentToYearDropdown}>
                            {years.map(year => (
                              <DropdownItem
                                key={year}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  if (+year < currentFromYear) {
                                    return;
                                  }
                                  setCurrentToYear(+year);
                                  setRange({
                                    ...range,
                                    end: range.end.utc(true).set('year', +year),
                                  });
                                  setShowCurrentToYearDropdown(false);
                                }}>
                                {year}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <Dropdown ref={toDateHourRef} onClose={() => setShowCurrenTotHourDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrenTotHourDropdown(!showCurrentToHourDropdown)}>{currentToHour}</DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentToHourDropdown}>
                            {hours.map(hour => (
                              <DropdownItem
                                key={hour}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setCurrentToHour(+hour);
                                  setRange({
                                    ...range,
                                    end: range.end.utc(true).set('hour', +hour),
                                  });
                                  setShowCurrenTotHourDropdown(false);
                                }}>
                                {hour}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>

                        <Dropdown ref={toDateMinuteRef} onClose={() => setShowCurrentToMinuteDropdown(false)}>
                          <DropdownTrigger onShow={() => setShowCurrentToMinuteDropdown(!showCurrentToMinuteDropdown)}>{currentToMinutes}</DropdownTrigger>
                          <DropdownMenu className='date-picker-dropdown-menu' isOpen={showCurrentToMinuteDropdown}>
                            {minutes.map(minute => (
                              <DropdownItem
                                key={minute}
                                className='date-picker-dropdown-item'
                                onItemClick={() => {
                                  setCurrentToMinutes(+minute);
                                  setRange({
                                    ...range,
                                    end: range.end.utc(true).set('minute', +minute),
                                  });
                                  setShowCurrentToMinuteDropdown(false);
                                }}>
                                {minute}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </motion.div>

                      <motion.div
                        whileTap={{
                          scale: 0.95,
                          backgroundColor: '#e9e9e9',
                        }}
                        onClick={setNextMonth}
                        className='from-date-month-year-picker-icons-container mr-3'>
                        <ChevronRightIcon className='from-date-month-year-picker-icons' />
                      </motion.div>
                    </ForwardedDiv>
                    <ForwardedDiv className='weekdays'>
                      {weekDays.map(({ label, value }) => (
                        <motion.p key={value}>{label}</motion.p>
                      ))}
                    </ForwardedDiv>
                    <ForwardedDiv className='to-date'>
                      {getDateCells(false).map((cell, index) => (
                        <ForwardedDiv
                          key={`${cell.month()}-${cell.date()}`}
                          className={classNames(
                            'from-date-cell',
                            getHighlightedDays(cell) ? 'from-date-cell-selected' : '',
                            cell.isSame(range.start) ? 'from-date-cell-selected-start' : '',
                            cell.isSame(range.start) && range.end.diff(range.start, 'days') >= 1 ? 'from-date-cell-selected-start-range' : '',
                            cell.month() === range.end.month() && cell.date() === range.end.date() ? 'from-date-cell-selected-end' : '',
                            cell.month() === range.end.month() && cell.date() === range.end.date() && range.end.diff(range.start, 'days') >= 1
                              ? 'from-date-cell-selected-end-range'
                              : '',
                            getEndOfRowSelectedValue(index) ? 'from-date-cell-selected-end-of-row from-date-cell-selected-end-of-row-hover' : '',
                            getStartOfRowSelectedValue(index) ? 'from-date-cell-selected-start-of-row from-date-cell-selected-start-of-row-hover' : '',
                            cell.isSame(moment(startOfDay).utc(true).endOf('day')) ? 'from-date-cell-today' : '',
                            cell.month() !== toDateMonth ? 'from-date-cell-opacity' : ''
                          )}
                          onClick={() => {
                            handleSetRange(cell);
                            if (cell.month() !== toDateMonth && cell.isAfter(range.start)) {
                              setToDateMonth(cell.month());
                            }
                          }}
                          whileHover={{
                            cursor: 'pointer',
                          }}>
                          {(cell.isSame(range.start) || (cell.month() === range.end.month() && cell.date() === range.end.date())) && <div>{cell.date()}</div>}
                          {cell.date()}
                        </ForwardedDiv>
                      ))}
                    </ForwardedDiv>
                  </ForwardedDiv>
                </ForwardedDiv>
              </ForwardedDiv>

              <ForwardedDiv className='date-picker-actions'>
                <ForwardedDiv className='date-picker-actions-ranges'>
                  {customRanges.map((r, index) => (
                    <motion.button
                      className={classNames(
                        range.start.format() === r.value.start && range.end.format() === r.value.end ? 'date-picker-actions-ranges-selected' : ''
                      )}
                      key={r.label}
                      onClick={() => {
                        handleSetCustomRanges(r.value);
                      }}>
                      {r.label}
                      {index > 6 && (
                        <TrashIcon
                          onClick={e => {
                            e.stopPropagation();
                            handleDeleteCustomRange(r.id);
                          }}
                          className='date-picker-actions-ranges-trash-icon'
                        />
                      )}
                    </motion.button>
                  ))}
                  <motion.button className='date-picker-actions-ranges-add-button' onClick={() => setAddCustomRange(!addCustomRange)}>
                    <PlusIcon />
                    Add a range
                  </motion.button>
                </ForwardedDiv>
              </ForwardedDiv>

              {/** Custom ranges */}
              <AnimatePresence>
                {addCustomRange && (
                  <ForwardedDiv
                    key='custom-range'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.3,
                      ease: 'easeInOut',
                    }}
                    className='custom-range-add'>
                    <motion.label htmlFor='label'>Custom Range Label:</motion.label>

                    <motion.div id='custom-range-container' className='custom-range-label'>
                      <motion.input
                        id='custom-range-label'
                        maxLength={16}
                        onChange={e => {
                          setCustomRangeError('');
                          setCustomRangeLabel(e.target.value);
                        }}
                      />
                      <AnimatePresence>
                        {customRangeError && (
                          <motion.span key='range-error' className='custom-range-error' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            {customRangeError}
                          </motion.span>
                        )}
                      </AnimatePresence>
                    </motion.div>
                    <motion.button
                      className='custom-range-add-submit-button'
                      onClick={() => {
                        handleAddCustomRange(customRangeLabel, {
                          start: range.start.format(),
                          end: range.end.format(),
                        });
                      }}>
                      Add range
                    </motion.button>
                    <motion.button
                      className='custom-range-add-cancel-button'
                      onClick={() => {
                        setAddCustomRange(false);
                        setCustomRangeLabel('');
                        setCustomRangeError('');
                      }}>
                      Cancel
                    </motion.button>
                  </ForwardedDiv>
                )}
              </AnimatePresence>
              <ForwardedDiv className='dark-mode-switch'>
                <DarkModeSwitch onChange={e => setPickerTheme(e.target.checked ? 'dark' : 'light')} theme={pickerTheme} />
              </ForwardedDiv>
            </ForwardedDiv>
          )}
        </AnimatePresence>
      </ForwardedDiv>
    </AnimatePresence>
  );
};
